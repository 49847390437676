// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import "~@ng-select/ng-select/themes/default.theme.css";
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$bop-frontend-primary: mat.define-palette(mat.$indigo-palette);
$bop-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$bop-frontend-warn: mat.define-palette(mat.$red-palette);
// $my-typography: mat.define-typography-config($font-family: '"DM Sans", "Helvetica Neue", sans-serif');

// @include mat.typography-hierarchy($my-typography);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$bop-frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: $bop-frontend-primary,
      accent: $bop-frontend-accent,
      warn: $bop-frontend-warn
    ),
    // typography: $my-typography
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($bop-frontend-theme);

/* You can add global styles to this file, and also import other style files */

html {
  min-height: 100%;
  position: relative;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: "DM Sans", sans-serif, "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande";
  // background: url(./assets/img/loader.gif) center center no-repeat;
}

.mat-label {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #526570;
}

.footer-wrapper {
  height: 100px;
  * {
    padding: 0;
    margin: 0;
  }
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.875rem 0;
    width: 100%;

    .copyright {
      font-size: 0.625rem;
      padding-bottom: 0rem;
    }

    ul {
      font-size: 0.875rem;

      li {
        display: inline;
        list-style: none;
      }

      .seperator {
        padding: 0 0.625rem;
      }
    }

    @media only screen and (min-width: 768px) {
      // max-width: 1200px;
      margin: 2px;
      padding-top: 0.875rem;
      padding-bottom: 2rem;
      position: absolute;
      bottom: 0px;
      // left: 0px;
      right: 0px;
      .padding {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0px;
      }

      ul {
        flex-grow: 2;
        flex-shrink: 0;
        flex-basis: 0px;
        text-align: center;
      }

      .copyright {
        margin: 4px;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0px;
        text-align: right;
        padding-right: 0rem;
      }
    }
  }
}

.line {
  width: 1176px;
  height: 0px;

  /* GreyScale/02 */

  border: 1px solid #eae9e9;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.mat-button-toggle-checked {
  background-color: #526570 !important;
  color: #ffffff !important;
}

mat-button-toggle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 100px;
  height: 40px;
  overflow: visible;

  /* identical to box height, or 171% */

  /* Secondary/02 */

  color: #526570;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

mat-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  /* Secondary/02 */

  color: #526570;
}

.mat-button-toggle-checked {
  background-color: #526570;
  color: #ffffff;
  // background: ;
}

.mat-button-toggle {
  // font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 44px;
  /* identical to box height, or 171% */

  text-align: center;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 45px;
}

.continue-button {
  align-self: flex-end;
}

.required-field::after {
  content: "*";

  /* Text/Caption M Low */

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding-left: 4px;

  /* State/Error 01 */

  color: #da2a2a;
}

.iner-title {
  // font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  /* or 140% */

  display: flex;
  align-items: center;

  /* Secondary/01 */

  color: #2d373d;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}

label {
  width: 406px;
  height: 18px;

  /* Text/Caption M Low */

  // font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  /* Secondary/02 */

  color: #526570;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

select {
  // font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #9cb0bc;
}

option {
  // font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #9cb0bc;
}

p {
  // font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.field-box-w576 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 576px;
  height: 62px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.field-box-w576-h130 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 576px;
  height: 130px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.field-box-w376 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 376px;
  height: 62px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.field-box-w400 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 400px;
  height: 62px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.field-box-w176 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 176px;
  height: 62px;

  flex: none;
  order: 0;
  flex-grow: 0;

  
}

.field-box-w210 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 210px;
  height: 62px;

  flex: none;
  order: 0;
  flex-grow: 0;

  
}

.field-label-readonly {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  width: 77px;
  height: 18px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.field-value {
  width: 180px;
  height: 24px;
  font-size: 16px;
}

.field-label-w576 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 576px;
  height: 18px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.field-label-w376 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 376px;
  height: 18px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.field-label-w400 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 400px;
  height: 18px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.field-label-w176 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 176px;
  height: 18px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.field-label-w85 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 85px;
  height: 18px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.field-dropdown-w976 {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;

  width: 940px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #9cb0bc;
  border-radius: 2px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  color: #526570;
}

.field-input-w376 {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;

  width: 376px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #9cb0bc;
  border-radius: 2px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.field-input-w400 {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;

  width: 400px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #9cb0bc;
  border-radius: 2px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  color: #526570;
}

.field-input-w176 {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;

  width: 176px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #9cb0bc;
  border-radius: 2px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  color: #526570;
}

.field-input-w210 {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;

  width: 210px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #9cb0bc;
  border-radius: 2px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  color: #526570;
}

.section-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  width: 1176px;
  height: 32px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.title-text {
  width: 1176px;
  height: 32px;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;

  display: flex;
  align-items: center;

  color: #2d373d;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}

.row-frame-h118 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  width: 1176px;
  height: 118px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.row-frame-h62 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  width: 1176px;
  height: 62px;

  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.row-frame-h40 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  width: 1176px;
  height: 40px;

  flex: none;
  flex-grow: 0;
}

.toggle-group-w276 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  width: 276px;
  height: 40px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.toggle-group-w288 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  width: 288px;
  height: 40px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.unselected-button {
  color: #526570;
  border: 1px solid #cccfd2;
}

.selected-button {
  background: #526570;
  color: #ffffff;
  // border: 1px solid #CCCFD2;
  border-left: none;
}

.toggle-button-left-w144 {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 32px;

  width: 144.5px;
  height: 40px;

  // border: 1px solid #CCCFD2;
  border-radius: 8px 0px 0px 8px;
}

.toggle-button-right-w144 {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 32px;

  width: 144.5px;
  height: 40px;

  // border: 1px solid #CCCFD2;
  border-radius: 0px 8px 8px 0px;
}

.toggle-button-left-w138 {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 32px;

  width: 138.5px;
  height: 40px;

  // border: 1px solid #CCCFD2;
  border-radius: 8px 0px 0px 8px;
}

.toggle-button-right-w138 {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 32px;

  width: 138.5px;
  height: 40px;

  // border: 1px solid #CCCFD2;
  border-radius: 0px 8px 8px 0px;
}
.toggle-button-freeze-w138 {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 32px;

  width: 138.5px;
  height: 40px;

  background: #D3D3D3;
  color: #ffffff;
  // border: 1px solid #CCCFD2;
  border-left: none;
  // border: 1px solid #CCCFD2;
  border-radius: 0px 8px 8px 0px;
}

.field-box-w776 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 776px;
  height: 62px;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.field-box-w976 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 940px;
  height: 62px;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.info-icon {
  // height: 13.35px;
  // width: 23px;
  // border-radius: 0px;
  // color: #526570;
  font-size: 17px;
}

.field-label-w198 {
  width: 198px;
  height: 18px;

  /* Text/Caption M Low */

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.field-input-w576 {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;

  width: 576px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #9cb0bc;
  border-radius: 2px;

  
  line-height: 18px;
  font-family: sans-serif;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}

.field-box-w200 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 200px;
  height: 62px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.field-input-w200 {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;

  width: 200px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #9cb0bc;
  border-radius: 2px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.field-box-w735 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 735px;
  height: 62px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.field-box-w336 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 336px;
  height: 62px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

input.ng-invalid.ng-touched {
  border: #da2a2a 1px solid;
  background: #faf0f2;
}
input.ng-valid.ng-touched {
  border: 1px solid #008c47;
}
textarea.ng-invalid.ng-touched {
  border: #da2a2a 1px solid;
  background: #faf0f2;
}

textarea.ng-valid.ng-touched {
  border: 1px solid #008c47;
}

select.ng-invalid.ng-touched {
  border: #da2a2a 1px solid;
  background: #faf0f2;
}

select.ng-valid.ng-touched {
  border: 1px solid #008c47;
}

.field-label-w336 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 336px;
  height: 18px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.field-input-w336 {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;

  width: 336px;
  height: 40px;

  /* GreyScale/03 (White) */

  background: #ffffff;
  /* Secondary/03 */

  border: 1px solid #9cb0bc;
  border-radius: 2px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.field-box-w276 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 276px;
  height: 62px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.field-box-w135 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 8px;
  gap: 12px;

  width: 135px;
  height: 62px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.field-label-w750 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 750px;
  height: 18px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.field-label-w282 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 282px;
  height: 18px;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.field-label-w276 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 276px;
  height: 18px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.field-label-w261 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  width: 261px;
  height: 18px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.field-label-w210 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  width: 210px;
  height: 18px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.field-label-w125 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  width: 125px;
  height: 18px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.field-input-w276 {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;

  width: 276px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #9cb0bc;
  border-radius: 2px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  color: #526570;
}

.hint {
  width: 50px;
  height: 18px;

  /* Text/Caption M Low */

  line-height: 18px;

  /* Secondary/02 */

  color: #526570;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.radio-button-selected-w138 {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 32px;

  width: 138.5px;
  height: 40px;

  background: #526570;

  border: 2px solid #526570;
  border-radius: 8px 0px 0px 8px;

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px -1px;
}

.radio-button-unselected-w138 {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 32px;

  width: 138.5px;
  height: 40px;

  border: 1px solid #cccfd2;
  border-radius: 0px 8px 8px 0px;

  flex: none;
  order: 1;
  flex-grow: 1;
}

.field-main-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 8px;
  gap: 12px;

  width: 276px;
  height: 62px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.alert {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 276px;
  height: 18px;

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  /* State/Error 01 */

  color: #da2a2a;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}
.alert-numUnits {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 570px;
  height: 18px;

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  /* State/Error 01 */

  color: #da2a2a;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}
.alert-contractequip {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 1000px;
  height: 18px;

  font-weight: 400;
  font-size: 14px;
  line-height: 2px;

  /* State/Error 01 */

  color: #da2a2a;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}

.message-contractenhancer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 600px;
  height: 18px;

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  /* State/Error 01 */

  //color: #da2a2a;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}

.bop-stepper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  height: 72px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.progree-bg {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 147px;
  gap: 16px;

  width: 100%;
  height: 72px;

  background: #f5f6f7;
  /* Secondary/06 */

  border-width: 1px 0px;
  border-style: solid;
  border-color: #89969a;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.stepper{
  display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 40px;

width: 1033px;
height: 40px;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.progress-bar-step1{
  display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 16px;

width: 108px;
height: 40px;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.progress-bar-step2{
  display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 16px;

width: 114px;
height: 40px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}
.progress-bar-step3{
  display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 16px;

width: 109px;
height: 40px;


/* Inside auto layout */

flex: none;
order: 2;
flex-grow: 0;
}
.progress-bar-step4{
  display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 16px;

width: 186px;
height: 40px;


/* Inside auto layout */

flex: none;
order: 3;
flex-grow: 0;
}
.progress-bar-step5{
  display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 16px;

width: 151px;
height: 40px;


/* Inside auto layout */

flex: none;
order: 4;
flex-grow: 0;
}
.progress-bar-step6{
  display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 16px;

width: 165px;
height: 40px;


/* Inside auto layout */

flex: none;
order: 5;
flex-grow: 0;
}

.stepper-selected-state{
  width: 40px;
height: 40px;

border-radius: 25px;

box-sizing: border-box;

position: absolute;


background: #FFFFFF;
/* State/Success */

border: 1px solid #008C47;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.stepper-unselected-state{
  width: 40px;
height: 40px;

border-radius: 25px;
position: absolute;

/* Secondary/03 */

background: #9CB0BC;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.stepper-completed-state{
  width: 40px;
height: 40px;

border-radius: 25px;
position: absolute;

/* Secondary/03 */

background: #008C47;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.stepper-completed-state:hover {
  cursor: pointer;
}

.stepper-selected-text{
  position: relative;
left: 36%;
right: 20%;
top: 20%;
bottom: 20%;
// padding-left: 7px;

/* Text/Medium */

// font-family: 'DM Sans';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 21px;
display: flex;
align-items: center;
text-align: center;

/* Secondary/01 */

color: #2D373D;
}

.stepper-unselected-text{
  position: relative;
left: 36%;
right: 20%;
top: 20%;
bottom: 20%;
// padding-left: 7px;

/* Text/Medium */

// font-family: 'DM Sans';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 21px;
display: flex;
align-items: center;
text-align: center;

/* Secondary/01 */

color:#FFFFFF;;
}

.stepper-completed-text{
  position: relative;
  left: 36%;
  right: 20%;
  top: 20%;
  bottom: 20%;

/* Text/Medium */

// font-family: 'DM Sans';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 21px;
display: flex;
align-items: center;
text-align: center;

/* GreyScale/03 */

color: #FFFFFF;

}

.stepper1-title{
  display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 8px;

width: 52px;
height: 18px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}

.stepper-selected-label{
  width: fit-content;
height: 18px;
margin-left: 56px;
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 18px;

/* Secondary/01 */

color: #2D373D;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.stepper-unselected-label{
  width: fit-content;
height: 18px;
margin-left: 56px;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;

/* Secondary/01 */

 color: #526570;;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.stepper-completed-label{
  width: fit-content;
height: 18px;
margin-left: 56px;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;

/* Secondary/01 */

color: #2D373D;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.pointer {
  cursor: pointer;
}

.toggle-button-left-w144 {
  cursor: pointer;
  transition-duration: 0.2ms;
}

.toggle-button-left-w144:hover {
  background: #526570;
  color: #FFFFFF;
}

.toggle-button-right-w144 {
  cursor: pointer;
  transition-duration: 0.2ms;
}

.toggle-button-right-w144:hover {
  background: #526570;
  color: #FFFFFF;
}

.toggle-button-left-w138 {
  cursor: pointer;
  transition-duration: 0.2ms;
}

.toggle-button-left-w138:hover {
  background: #526570;
  color: #FFFFFF;
}

.toggle-button-right-w138 {
  cursor: pointer;
  transition-duration: 0.2ms;
}

.toggle-button-right-w138:hover {
  background: #526570;
  color: #FFFFFF;
}

.checkboxes {
  cursor: pointer;
}

.dropdown-text {
  line-height: 24px;
  color: #526570;

  font-size: 16px;

  transition-duration: 0.2ms;
}

select.field-input-w400 option:hover {
  box-shadow: #526570;
  color: #FFFFFF;
}

/* Tooltip container */
.link-form-tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.link-form-tooltip .link-form-tooltiptext {
  visibility: hidden;
  width: 123px;
  background-color: #2D373D;
  color: #FFFFFF;
  padding: 10px;
  border-radius: 5px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  left: 90%;
  margin-left: 10px;
  margin-top: -6px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.link-form-tooltip .link-form-tooltiptext::after {
  content: "";
  position: absolute;
  top: 30%;
  right: 100%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #2D373D transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.link-form-tooltip:hover .link-form-tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Link Form text styling */
.link-form-link:link {
  color: #FFFFFF;
}

.link-form-link:visited {
  color: #FFFFFF;
}

.link-form-link:hover {
  color: #FFFFFF;
}

.link-form-link:active {
  color: #FFFFFF;
}
